.list {
    display: flex;
    flex-wrap: wrap;
}

@media all and (min-width: 40em) {
    .list-item {
        width: 50%;
    }
}

@media all and (min-width: 60em) {
    .list-item {
        width: 33.33%;
    }
}